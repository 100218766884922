<template>
  <div>
    <div class="boxListTop">- 设备管理 -</div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card class="box-card" shadow="hover" v-loading="loading">
            <div class="pane">
              <p class="pane_label">服务器硬盘使用量</p>
              <div
                v-if="!loading"
                ref="chart"
                id="chart"
                style="width: 100%; height: 260px"
              ></div>
              <span id="del_sapn_inner">(删除考试即可清理磁盘)</span>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">
                实验教学测评平台<el-button
                  size="mini"
                  icon="el-icon-setting"
                  @click="setBtn(11)"
                  >设置</el-button
                >
              </p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="platformVersion"
                      placeholder
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="version_inner">
                <el-upload
                  ref="uploadPlatformRef"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  :show-file-list="false"
                  :file-list="uploadFileList"
                  :http-request="uploadServerPackage"
                >
                  <div>
                    <el-button
                      v-throttle
                      type="primary"
                      icon="el-icon-upload"
                      size="medium"
                      @click="uploadVersion(11)"
                      >上传新版本</el-button
                    >
                  </div>
                </el-upload>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">
                监考平台管理终端管理<el-button
                  size="mini"
                  icon="el-icon-setting"
                  @click="setBtn(31)"
                  >设置</el-button
                >
              </p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="invigilatorVersion"
                      placeholder
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                  <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
                </el-form>
              </div>
              <div class="version_inner">
                <el-upload
                  ref="uploadInvigilatorRef"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  :show-file-list="false"
                  :file-list="uploadFileList"
                  :http-request="uploadServerPackage"
                >
                  <div>
                    <el-button
                      v-throttle
                      type="primary"
                      icon="el-icon-upload"
                      size="medium"
                      @click="uploadVersion(31)"
                      >上传新版本</el-button
                    >
                  </div>
                </el-upload>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">
                阅卷客户端管理
                <el-button
                  size="mini"
                  icon="el-icon-setting"
                  @click="getMarkTerminalConfigFunc"
                  >设置</el-button
                >
              </p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="markTerminalVersion"
                      placeholder
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                  <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
                </el-form>
              </div>
              <div class="version_inner">
                <el-upload
                  ref="uploadMarkTerminalRef"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  :show-file-list="false"
                  :file-list="uploadFileList"
                  :http-request="uploadServerPackage"
                >
                  <div>
                    <el-button
                      v-throttle
                      type="primary"
                      icon="el-icon-upload"
                      size="medium"
                      @click="uploadVersion(51)"
                      >上传新版本</el-button
                    >
                  </div>
                </el-upload>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6" style="margin-top: 20px" v-if="0">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">Windows考生端管理</p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="input"
                      placeholder
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                  <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
                </el-form>
              </div>
              <div class="version_inner">
                <el-button
                  v-throttle
                  v-show="0"
                  type="primary"
                  icon="el-icon-upload"
                  size="medium"
                  >上传新版本</el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6" style="margin-top: 20px">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">
                AI算力盒管理<el-button
                  size="mini"
                  icon="el-icon-setting"
                  @click="setBtn('AI')"
                  >设置</el-button
                >
              </p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="input"
                      placeholder
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="version_inner">
                <el-button
                  v-throttle
                  v-show="0"
                  type="primary"
                  icon="el-icon-upload"
                  size="medium"
                  >上传新版本</el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6" style="margin-top: 20px">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">
                考生套件管理<el-button
                  size="mini"
                  icon="el-icon-setting"
                  @click="setBtn(41)"
                  >设置</el-button
                >
              </p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="examineeAndroidVersion"
                      :disabled="true"
                      placeholder
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="version_inner">
                <el-upload
                  ref="uploadExamineeAndroidRef"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  :show-file-list="false"
                  :file-list="uploadFileList"
                  :http-request="uploadServerPackage"
                >
                  <div>
                    <el-button
                      v-throttle
                      type="primary"
                      icon="el-icon-upload"
                      size="medium"
                      @click="uploadVersion(41)"
                      >上传新版本</el-button
                    >
                  </div>
                </el-upload>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" style="margin-top: 20px">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">FTP服务管理</p>
              <div class="pane_content">
                <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="studentTerminalFTPVersion"
                      :disabled="true"
                      placeholder
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="version_inner">
                <el-upload
                  ref="uploadStudentTerminalFTPRef"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  :show-file-list="false"
                  :file-list="uploadFileList"
                  :http-request="uploadServerPackage"
                >
                  <div>
                    <el-button
                      v-throttle
                      type="primary"
                      icon="el-icon-upload"
                      size="medium"
                      @click="uploadVersion(42)"
                      >上传新版本</el-button
                    >
                  </div>
                </el-upload>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="boxListTop" style="margin-top: 20px; margin-bottom: 0">
      - 软件更新 -
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" style="margin-top: 20px" v-loading="loading2">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">实验教学测评平台</p>
              <div class="pane_content">
                当前版本：{{ versionNum }}
                <!-- <el-form :inline="true">
                  <el-form-item label="当前版本：">
                    <el-input
                      v-model="versionNum"
                      :disabled="true"
                      placeholder
                    ></el-input>
                  </el-form-item>
                </el-form> -->
              </div>
              <div class="version_inner" style="margin-top: 120px">
                <el-button
                  v-throttle
                  type="primary"
                  icon="el-icon-refresh"
                  size="medium"
                  @click="updateVersionFunc"
                  >3代版本到4代更新</el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="boxListTop" style="margin-top: 20px; margin-bottom: 0">
      - 数据更新 -
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" style="margin-top: 20px" v-loading="loading3">
          <el-card class="box-card" shadow="hover">
            <div class="pane">
              <p class="pane_label">数据清除</p>
              <div
                class="pane_content"
                style="margin-top: 20px; margin-left: 0"
              >
                <el-checkbox v-model="deleteDataList.deleteTeacher"
                  >教师管理</el-checkbox
                >
                <el-checkbox v-model="deleteDataList.deleteStudent"
                  >学生管理</el-checkbox
                >
                <el-checkbox v-model="deleteDataList.deleteSchool">
                  学校管理</el-checkbox
                >
                <el-checkbox v-model="deleteDataList.deleteQuestion"
                  >考题配置</el-checkbox
                >
                <el-checkbox disabled v-model="deleteDataList.deleteExam"
                  >考试安排</el-checkbox
                >
                <div style="font-size: 14px; margin-top: 10px; color: #999">
                  说明：勾选一键清除会把勾选上的模块的数据全部清除
                </div>
                <div
                  style="
                    font-size: 14px;
                    margin-top: 10px;
                    color: #ff0d0d;
                    display: flex;
                  "
                >
                  <svg
                    t="1709198753982"
                    class="icon"
                    viewBox="0 0 1026 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4237"
                    width="48"
                    height="48"
                    style="margin-right: 10px"
                  >
                    <path
                      d="M1004.657 801.716 602.263 91.599c-49.213-86.817-129.646-86.817-178.866 0L21.004 801.716c-49.207 86.906-8.949 157.798 89.388 157.798l804.877 0C1013.606 959.514 1053.825 888.622 1004.657 801.716zM544.635 832.216l-63.649 0 0-63.649 63.649 0L544.635 832.216zM544.635 641.27l-63.649 0L480.986 259.377l63.649 0L544.635 641.27z"
                      p-id="4238"
                      fill="#FF0D0D"
                    ></path>
                  </svg>
                  <div>
                    请确保没有正在进行中的考试和考试任务，且平台无其他数据操作，例如导入数据等，删除前请进行数据归档
                  </div>
                </div>
              </div>
              <div class="version_inner">
                <el-button
                  v-throttle
                  type="primary"
                  size="medium"
                  @click="deleteDataBtn"
                  >一键清除</el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 弹框 -->
    <el-dialog
      title="阅卷客户端分配设置"
      :visible.sync="dialogVisibleTerminalConfig"
      width="562px"
    >
      <div class="tabsDialog">
        <el-tabs v-model="activeName">
          <el-tab-pane label="分配设置" name="first">
            <el-form
              ref="terminalConfigData"
              :model="terminalConfigData"
              label-width="120px"
              class="terminalConfigForm"
            >
              <el-form-item label="视频存储位置">
                <el-select
                  v-model="terminalConfigData.videoStorageLocation"
                  size="mini"
                >
                  <el-option
                    v-for="item in videoStorageLocationList"
                    :key="item"
                    :label="`本地${item}盘`"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="视频解码方式">
                <el-radio-group v-model="terminalConfigData.videoDecodingType">
                  <el-radio :label="1">软解</el-radio>
                  <el-radio :label="2">硬解</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="人脸打码模型">
                <el-radio-group v-model="terminalConfigData.faceCodingModel">
                  <el-radio :label="1">C模型</el-radio>
                  <el-radio :label="2">L模型</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启人脸打码">
                <el-switch
                  v-model="terminalConfigData.enableFaceCoding"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
              <el-form-item label="是否强制刷新">
                <el-switch
                  v-model="terminalConfigData.hasForceRefresh"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="显示设置" name="second">
            <div class="dialogBox">
              <el-form
                ref="terminalConfigData"
                :model="terminalConfigData"
                style="margin-left: 20px"
              >
                <el-form-item label="阅卷端名称" class="formItem">
                  <el-input
                    v-model="terminalConfigData.displayName"
                    show-word-limit
                    style="width: 380px"
                    type="text"
                    :maxlength="8"
                  ></el-input>
                </el-form-item>
                <el-form-item label="阅卷端logo">
                  <div style="display: flex; align-items: center">
                    <el-upload
                      class="avatar-uploader"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :show-file-list="false"
                      :http-request="uploadLogoUrlImg"
                      ref="uploadLogoUrlImg"
                      :limit="1"
                    >
                      <img
                        v-if="dialogVisibleSetParam.logoUrl"
                        :src="this.$imageAddress(dialogVisibleSetParam.logoUrl)"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div style="margin: 0 10px">
                      <el-switch
                        class="tablescope"
                        v-model="terminalConfigData.logoEnable"
                        :active-value="1"
                        :inactive-value="0"
                        inactive-text="隐藏"
                        active-text="显示"
                      ></el-switch>
                      <div style="line-height: 20px">
                        建议使用160*90，2M以内的图片 支持jpg、png、svg图片
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleTerminalConfig = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="updateMarkTerminalConfigFunc"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="显示设置" :visible.sync="dialogVisibleSet" width="562px">
      <div class="dialogBox">
        <el-form
          ref="dialogVisibleSetParam"
          :model="dialogVisibleSetParam"
          style="margin-left: 20px"
        >
          <el-form-item :label="dialogVisibleListTitle.name" class="formItem">
            <el-input
              v-model="dialogVisibleSetParam.displayName"
              show-word-limit
              type="text"
              style="width: 380px"
              :maxlength="dialogVisibleListTitle.Max"
            ></el-input>
          </el-form-item>
          <el-form-item :label="dialogVisibleListTitle.logo">
            <div style="display: flex; align-items: center">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadLogoUrlImg"
                ref="uploadLogoUrlImg"
                :limit="1"
              >
                <img
                  v-if="dialogVisibleSetParam.logoUrl"
                  :src="this.$imageAddress(dialogVisibleSetParam.logoUrl)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="margin: 0 10px">
                <el-switch
                  class="tablescope"
                  v-model="dialogVisibleSetParam.logoEnable"
                  :active-value="1"
                  :inactive-value="0"
                  inactive-text="隐藏"
                  active-text="显示"
                ></el-switch>
                <div
                  style="
                    line-height: 20px;
                    font-size: 14px;
                    color: rgb(127, 127, 127);
                  "
                >
                  建议使用160*90，2M以内的图片 支持jpg、png、svg图片
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否强制刷新:">
            <el-switch
              v-model="dialogVisibleSetParam.hasForceRefresh"
              :active-value="1"
              :inactive-value="0"
              style="margin-left: 330px"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleSet = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="updateBtn"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="清除确认" :visible.sync="dialogVisibleDel" width="300px"
      ><el-form ref="formLion" :model="formLion">
        <el-form-item prop="account">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="formLion.account"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formLion.password"
            placeholder="密码"
            show-password
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDel = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="!formLion.password.length"
          @click="verifyLoginBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { fileSingle } from '@/api/fileUpload/fileUpload.js'
import {
  getServerDiskApi,
  getServerPackageListApi,
  getServerPackageTypeApi,
  uploadServerPackageApi,
  // 终端配置
  getAICalculationTerminalConfig,
  resetAICalculationTerminalConfig,
  updateAICalculationTerminalConfig,
  getMarkTerminalConfig,
  resetMarkTerminalConfig,
  updateMarkTerminalConfig,
  getPlatformFrontTerminalConfig,
  resetPlatformFrontTerminalConfig,
  updatePlatformFrontTerminalConfig,
  getStudentTerminalConfig,
  resetStudentTerminalConfig,
  updateStudentTerminalConfig,
  getTeacherTerminalConfig,
  resetTeacherTerminalConfig,
  updateTeacherTerminalConfig,
} from '@/api/system/deviceManage.js'
import { isEmpty } from '@/utils/util'
import {
  getVersion,
  updateVersion,
  verifyLogin,
  deleteSysExamData,
} from '@/api/auth.js'
import { getName } from '@/utils/token.js'
import { debounce } from '@/utils/util'
export default {
  name: 'deviceManage',
  data() {
    return {
      loading: false,
      charts: {
        chart: '',
      },
      input: '没有升级包，请上传',
      platformVersion: '没有升级包，请上传',
      invigilatorVersion: '没有升级包，请上传',
      markTerminalVersion: '没有升级包，请上传',
      examineeAndroidVersion: '没有升级包，请上传',
      studentTerminalFTPVersion: '没有升级包，请上传',
      serverPackageList: [],
      uploadType: 0,
      uploadFileList: [],
      uploadComponentRef: '',
      diskResult: {},
      // 分配设置弹框
      dialogVisibleTerminalConfig: false,
      // 参数
      terminalConfigData: {
        // // 	是否开启人脸打码， 1--开启，0--关闭
        // enableFaceCoding: 0,
        // // 人脸打码模型， 1--C模型，2--L模型
        // faceCodingModel: 0,
        // // 是否强制刷新，1--是，0--不是
        // hasForceRefresh: 0,
        // // 视频解码方式, 1--软解，2--硬解
        // videoDecodingType: 0,
        // // 视频存储位置，盘符名称
        // videoStorageLocation: '',
      },
      videoStorageLocationList: Array.from({ length: 24 }, (_, i) =>
        String.fromCharCode(67 + i)
      ),

      // 设置弹框
      dialogVisibleSet: false,
      // 参数
      dialogVisibleSetParam: {
        displayName: '',
        hasForceRefresh: 0,
        logoEnable: 0,
        logoUrl: '',
      },
      // key
      dialogVisibleKey: '',
      dialogVisibleListTitle: {
        name: '',
        logo: '',
        Max: 8,
      },
      activeName: 'first',
      // 软件更新
      // 版本
      versionNum: 'msetp.school.v3.0.0',
      loading2: false,
      // 数据清除
      deleteDataList: {
        deleteExam: true,
        deleteQuestion: true,
        deleteSchool: true,
        deleteStudent: true,
        deleteTeacher: true,
      },
      // 登录验证
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        loginType: 1,
        password: '',
        userType: 0,
        loginTerminalType: 11,
      },
      dialogVisibleDel: false,
      loading3: false,
    }
  },
  mounted() {
    this.formLion.account = getName()
    this.init()
    window.onresize = () => {
      this.reloadChart()
    }
  },
  watch: {},
  methods: {
    init() {
      this.getServerPackageList()
      this.getServerDisk()
      this.getVersionFunc()
    },
    // 数据更新
    // 数据清除
    deleteDataBtn: debounce(function () {
      this.formLion.password = ''
      this.dialogVisibleDel = true
    }),
    // 身份判断
    verifyLoginBtn: debounce(function () {
      verifyLogin(this.formLion)
        .then((res) => {
          if (res.success) {
            this.dialogVisibleDel = false
            this.deleteDataApi()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    }),
    // 清除请求
    deleteDataApi() {
      this.loading3 = true
      const newDataList = {}
      for (const [key, value] of Object.entries(this.deleteDataList)) {
        newDataList[key] = value ? 1 : 0
      }
      deleteSysExamData(newDataList)
        .then((res) => {
          this.loading3 = false
          if (res.success) {
            this.$message.success('成功清除')
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 版本信息
    getVersionFunc() {
      getVersion().then((res) => {
        console.log(res)
        if (res.success) {
          this.versionNum = res.data
        } else {
          this.versionNum = `版本获取失败，code:${res.code}`
        }
      })
    },
    // 3代版本到4代更新
    updateVersionFunc: debounce(function () {
      this.loading2 = true
      updateVersion()
        .then((res) => {
          if (res.success) {
            this.$message.success('更新成功')
            this.getVersionFunc()
          } else {
            this.$message.info(res.msg)
          }
          this.loading2 = false
        })
        .catch((err) => {
          this.loading2 = false
        })
    }),
    //获取磁盘信息
    getServerDisk() {
      getServerDiskApi().then((res) => {
        if (res.success) {
          this.diskResult = {}
          this.diskResult = res.data
          console.log(this.diskResult)
          this.initChart()
        }
      })
    },
    initChart() {
      var chart = this.$echarts.init(this.$refs.chart)
      this.charts.chart = chart
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'item',
          // formatter: "{a} <br/>{b}: {c} ({d}%)",
          formatter: '{b}<br/>{c}GB ({d}%)',
        },
        legend: {
          orient: 'vertical',
          top: '5%',
          left: '0',
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['45%', '65%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: Number(
                  this.diskResult.diskUsableSpace / 1024 / 1024 / 1024
                ).toFixed(2),
                name: '磁盘已使用',
              },
              {
                value: Number(
                  this.diskResult.diskFreeSpace / 1024 / 1024 / 1024
                ).toFixed(2),
                name: '磁盘未使用',
              },
            ],
          },
        ],
        color: ['red', 'black'],
      }
      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option)
    },
    //获取安装包列表
    getServerPackageList() {
      getServerPackageListApi().then((res) => {
        if (res.success) {
          this.serverPackageList = []
          this.serverPackageList = res.data
          if (!isEmpty(this.serverPackageList)) {
            this.serverPackageList.forEach((item) => {
              if (!isEmpty(item)) {
                let _version = item.versionCode || '没有升级包，请上传'
                if (!isEmpty(item.type)) {
                  switch (Number(item.type)) {
                    case 11:
                      this.platformVersion = _version
                      break
                    case 31:
                      this.invigilatorVersion = _version
                      break
                    case 41:
                      this.examineeAndroidVersion = _version
                      break
                    case 42:
                      this.studentTerminalFTPVersion = _version
                      break
                    case 51:
                      this.markTerminalVersion = _version
                      break
                  }
                }
              }
            })
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    // 上传新版本
    uploadVersion(type) {
      const uploadComponentRefMap = {
        11: 'uploadPlatformRef',
        31: 'uploadInvigilatorRef',
        41: 'uploadExamineeAndroidRef',
        42: 'uploadStudentTerminalFTPRef',
        51: 'uploadMarkTerminalRef',
      }
      this.uploadType = type
      this.uploadComponentRef = uploadComponentRefMap[type]
    },
    // upload组件钩子事件
    beforeAvatarUpload(file) {
      this.uploadFileList = []
      this.uploadFileList.push(file)
    },
    handleAvatarSuccess(response, file, fileList) {},
    uploadServerPackage() {
      let fd = new FormData()
      fd.append('file', this.uploadFileList[0])
      fd.append('type', this.uploadType)
      const loading = this.globalLoading('正在上传,请稍后...')
      uploadServerPackageApi(fd)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('上传成功')
            this.getServerPackageList()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
          this.uploadType = 0
          this.clearFile()
        })
        .catch(() => {
          loading.close()
        })
    },
    clearFile() {
      let _ref = this.uploadComponentRef
      this.$refs[_ref].clearFiles()
      this.uploadFileList = []
    },
    reloadChart() {
      this.charts.chart.resize()
    },
    // 获取
    getMarkTerminalConfigFunc() {
      getMarkTerminalConfig()
        .then((res) => {
          console.log('获取【阅卷客户端】配置', res)
          if (res.success) {
            let {
              enableFaceCoding,
              faceCodingModel,
              hasForceRefresh,
              videoDecodingType,
              videoStorageLocation,
              displayName,
              logoEnable,
              logoUrl,
            } = res.data
            this.terminalConfigData = {
              enableFaceCoding,
              faceCodingModel,
              hasForceRefresh,
              videoDecodingType,
              videoStorageLocation,
              displayName,
              logoEnable,
              logoUrl,
            }
            this.dialogVisibleSetParam.logoUrl = logoUrl
            this.dialogVisibleTerminalConfig = true
          }
        })
        .catch((err) => {})
    },
    // 重置
    // resetMarkTerminalConfigFunc() {
    //   resetMarkTerminalConfig()
    //     .then((res) => {})
    //     .catch((err) => {})
    // },
    // 更新
    updateMarkTerminalConfigFunc() {
      if (!this.terminalConfigData.displayName) {
        return this.$message.info('名称不能为空')
      }
      this.terminalConfigData.logoUrl = this.dialogVisibleSetParam.logoUrl
      updateMarkTerminalConfig(this.terminalConfigData)
        .then((res) => {
          console.log('更新', res)
          if (res.success) {
            this.$message.success('保存成功')
            this.dialogVisibleTerminalConfig = false
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 单个上传
    async fileSingleFunc(e) {
      let fd = new FormData()
      fd.append('code', 'default')
      fd.append('file', e.file)
      try {
        let res = await fileSingle(fd)
        if (res.success) {
          return res.data.url
        } else {
          this.$message.warning(res.msg)
        }
      } catch (err) {
        // 处理错误
      }
    },
    // 头像
    async uploadLogoUrlImg(e) {
      let url = await this.fileSingleFunc(e)
      this.dialogVisibleSetParam.logoUrl = url
      this.$refs.uploadLogoUrlImg.clearFiles()
      this.$forceUpdate()
    },
    // 设置按钮
    setBtn(key) {
      this.dialogVisibleKey = key
      const getApiMap = {
        11: getPlatformFrontTerminalConfig,
        31: getTeacherTerminalConfig,
        41: getStudentTerminalConfig,
        AI: getAICalculationTerminalConfig,
      }
      const getApiMapShowText = {
        11: {
          name: '平台名称:',
          logo: '平台logo:',
          Max: 11,
        },
        31: {
          name: '监考端名称:',
          logo: '监考端logo:',
          Max: 8,
        },
        41: {
          name: '学生端名称:',
          logo: '学生端logo:',
          Max: 8,
        },
        AI: {
          name: '算力盒名称:',
          logo: '算力盒logo:',
          Max: 20,
        },
      }
      this.dialogVisibleListTitle = getApiMapShowText[key]
      getApiMap[this.dialogVisibleKey]()
        .then((res) => {
          if (res.success) {
            let { displayName, hasForceRefresh, logoEnable, logoUrl } = res.data
            this.dialogVisibleSetParam = {
              displayName,
              hasForceRefresh,
              logoEnable,
              logoUrl: logoUrl ? logoUrl : '',
            }
            this.dialogVisibleSet = true
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },

    // 设置确定
    updateBtn() {
      if (!this.dialogVisibleSetParam.displayName) {
        return this.$message.info('名称不能为空')
      }
      const updateApiMap = {
        11: updatePlatformFrontTerminalConfig,
        31: updateTeacherTerminalConfig,
        41: updateStudentTerminalConfig,
        AI: updateAICalculationTerminalConfig,
      }
      updateApiMap[this.dialogVisibleKey](this.dialogVisibleSetParam)
        .then((res) => {
          if (res.success) {
            if (res.success) {
              this.$message.success('提交成功!')
              this.dialogVisibleSet = false
              if (this.dialogVisibleKey == 11) {
                this.$store.dispatch(
                  'GET_PlatformName',
                  this.dialogVisibleSetParam.displayName
                )
              }
            } else {
              this.$message.info(res.msg)
            }
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },

    // // 设置重置
    // resetBtn() {
    //   const resetApiMap = {
    //     11: resetPlatformFrontTerminalConfig,
    //     31: resetTeacherTerminalConfig,
    //     41: resetStudentTerminalConfig,
    //     AI: resetAICalculationTerminalConfig,
    //   }
    //   resetApiMap[this.dialogVisibleKey]()
    //     .then((res) => {
    //       if (res.success) {
    //       } else {
    //         this.$message.info(res.msg)
    //       }
    //     })
    //     .catch((err) => {})
    // },
  },
}
</script>
<style lang="scss" scoped>
.boxListTop {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
}
.pane {
  height: 320px;
  padding: 10px;
}

.pane_label {
  font-size: 15px;
  margin: 0;
  height: 45px;
  line-height: 45px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pane_content {
  margin: 50px 0 0 30px;
}

.pane ::v-deep form {
  height: 85px;
}

.el-link--inner {
  color: #999;
  font-size: 15px;
  font-weight: 500;
  margin-left: 27%;
}

// ::v-deep .el-upload--text {
// width: 100%;
// }

.version_inner {
  text-align: center;
  margin-top: 50px;
}

#del_sapn_inner {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  color: #999;
  width: 100%;
}
.terminalConfigForm ::v-deep .el-form-item {
  width: 500px;
  // display: flex;
  background: #f8f8f8;
  margin: 0;
  padding: 6px 12px 6px 0;
  border-bottom: 1px solid #eeeeee;
  .el-form-item__content {
    float: right;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 130px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  max-width: 130px;
  max-height: 130px;
  display: block;
}
::v-deep .formItem {
  .el-form-item__label {
    position: relative;
    &::after {
      content: '*';
      display: inline-block;
      color: rgb(245, 108, 108);
      position: absolute;
      left: -10px;
      top: 0px;
    }
  }
}
.dialogBox {
  padding: 10px;
  background-color: rgb(248, 248, 248);
  border-radius: 10px;
}
.dialog-footer {
  text-align: center;
}
::v-deep .tabsDialog {
  margin-top: -20px;
  .el-tabs__item.is-top {
    font-size: 18px;
  }
}
</style>
<style lang="scss">
.tablescope {
  margin-left: -40px;
  .el-switch__label--left {
    position: relative;
    left: 60px;
    color: #fff;
    z-index: -1111;
  }
  .el-switch__core {
    width: 60px !important;
  }
  .el-switch__label--right {
    position: relative;
    right: 60px;
    color: #fff;
    z-index: -1111;
  }
  .el-switch__label--right.is-active {
    z-index: 1111;
    color: #fff !important;
  }
  .el-switch__label--left.is-active {
    z-index: 1111;
    color: #9c9c9c !important;
  }
}
</style>
